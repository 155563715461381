import ProfilePreviewPage from 'features/manfredite-profile/ManfrediteProfilePage';
import { ManfrediteProfilePageProps } from 'features/manfredite-profile/ManfrediteProfilePage.types';
import { usePageTrack } from 'hooks/usePageTrack';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { DEFAULT_LOCALE } from 'services/multilingual.service';
import { ServiceProfilePageInfo } from 'services/service-profile-page-info';
import { getLoggedUserToken } from 'utils/access-token';
import { getNotFoundUrl } from 'utils/app-urls';
import { createGetServerSideProps } from 'utils/ssr';

const Page: React.FC<ManfrediteProfilePageProps> = (props) => {
  usePageTrack('Public Profile - Page Loaded');

  return <ProfilePreviewPage {...props} />;
};

export default Page;

export const getServerSideProps: GetServerSideProps<ManfrediteProfilePageProps> =
  createGetServerSideProps(async ({ locale, params, req, res }) => {
    const redirect404 = {
      redirect: {
        destination: getNotFoundUrl(locale),
        permanent: false
      }
    };

    const slug = params?.slug?.toString();

    if (!slug) {
      return redirect404;
    }

    const accessToken = await getLoggedUserToken(req, res);
    const result = await ServiceProfilePageInfo.get({ slug, accessToken });

    if (!result) {
      return redirect404;
    }

    const { publicManfredite, showToolbar, publicProfileActive, view } = result;

    const profileLocale = (
      publicManfredite.preferredLanguage ||
      locale ||
      DEFAULT_LOCALE
    ).toLowerCase();

    return {
      props: {
        ...(await serverSideTranslations(profileLocale, [
          'common',
          'publicProfile',
          'profile-preview',
          'portal-layout'
        ])),
        publicManfredite,
        profileLocale,
        slug,
        showToolbar,
        publicProfileActive,
        view
      }
    };
  });
