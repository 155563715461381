import { LoggedUserContext } from 'context/logged-user/loggeduser.context';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useContext, useEffect, useRef } from 'react';
import ServiceEventTracking from 'services/service.event-tracking';
import { UsePageTrack } from './usePageTrack.types';

export const usePageTrack: UsePageTrack = (event) => {
  const { user, loadingUser } = useContext(LoggedUserContext);
  const { isEnabled, isLoading: isLoadingFeatureFlag } = useFeatureFlag(
    'talent_manfredite_profile_page_tracking'
  );
  const alreadySent = useRef(false);

  useEffect(() => {
    // Still loading feature flag values
    if (isLoadingFeatureFlag) {
      return;
    }

    // If event is "Public Profile - Page Loaded" and feature flag is disabled, do not track
    const isPublicProfileTrackDisabled =
      !isLoadingFeatureFlag &&
      !isEnabled &&
      event === 'Public Profile - Page Loaded';

    if (isPublicProfileTrackDisabled) {
      return;
    }

    // When user info (if any) is already loaded and event is not tracked yet => Track it
    if (!alreadySent.current) {
      const urlParams = new URLSearchParams(window.location.search);

      // Identify user with campaign traits if user is logged in and campaign exists
      if (urlParams.get('utm_campaign')) {
        identifyUserWithCampaign(urlParams);
      }

      ServiceEventTracking.page(event, {
        userId: user?.id ? `userId-${user.id}` : undefined
      });

      alreadySent.current = true;
    }
  }, [loadingUser, event, user?.id, isLoadingFeatureFlag, isEnabled]);
};

function identifyUserWithCampaign(urlParams: URLSearchParams): void {
  ServiceEventTracking.identifyAnonymous({
    'campaign.name': urlParams.get('utm_campaign')?.toLowerCase() || undefined,
    'campaign.source': urlParams.get('utm_source')?.toLowerCase() || undefined,
    'campaign.medium': urlParams.get('utm_medium')?.toLowerCase() || undefined,
    'campaign.term': urlParams.get('utm_term')?.toLowerCase() || undefined,
    'campaign.content':
      urlParams.get('utm_content')?.toLowerCase() || undefined,
    'campaign.id': urlParams.get('utm_id')?.toLowerCase() || undefined
  });
}
